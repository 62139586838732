.active{
  background-color: #168986;
  
}

.navbar {
    //  width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #92C03C;  
    color: black;
    position: relative;
    z-index: 1000;

    .full-menu{
      display: flex;
      align-items: center; /* Center items vertically */
      justify-content: space-between; /* Distribute items evenly along the row */
      flex-grow: 1; /* Expand to fill available space */
    }

    .hamburger-menu {
      display: none; // Hide by default on larger screens
      cursor: pointer;
      margin-right: 10px;
  
      .bar {
        width: 30px;
        height: 3px;
        background-color: white;
        margin: 6px 0;
      }
    }
      
  
    .logo-container {
        width: 10%;
        height: 10%;

        .logo{
            width: 10%;
            height: 20%;
        }
    }


    .nav-links {
      display: flex;
      justify-content: center;
      list-style: none;
      margin-left: 35%;
      padding: 0;

      .link{
        text-decoration: none;
        color: black;
        font-size: large;
        font-weight: 600;
        border-radius: 0.5rem;
      }
      .link:hover{
        font-size: larger;
        font-weight: 700;
        text-decoration: underline #168986 5px;
      }

      .services{
        font-size: large;
        font-weight: 600;
      }

  
      li {
        margin-right: 20px;
        position: relative;
        list-style: none;
        

        .sub-link{
            text-decoration: none;
            font-size: large;
            font-weight: 600;
            color: black;
        
            .sub-link:hover{
              text-decoration: underline #168986 5px;
            }
          }


  
        &:hover .sub-links {
          display: block;
        }
  
        .sub-links {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          background-color: #92C03C;
          padding: 10px;
          list-style: none;
          margin: 0;
  
          li {
            margin: 0;

          }

          .sub-links li:hover {
            display: block;
            text-decoration: underline #168986 5px;
          }
        }
      }
    }
  
    .btn {
      background-color: #168986;
      border: none;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 4px;
    }
    .btn:hover{
      background-color: #44949F;
    }
    
  }
    // Hide nav-links by default
    .nav-links {
      display: none;
    }
  
    // Show nav-links when showNav is true
    .show-nav {
      display: block;
    }
  

  @media only screen and (max-width: 768px) {
    .navbar {
      .hamburger-menu {
        display: block; // Show only on smaller screens
      }
  
      .full-menu {
        display: none; // Hide by default on smaller screens
      }
  
      .show-nav {
        display: flex; // Show full menu when toggled on smaller screens
        flex-direction: column;
        align-items: center;
        background-color: #92C03C;
        position: absolute;
        top: 60px; // Adjust as needed
        right: 0;
        width: 50%;
        padding: 20px 0;
        border-radius: 9px;

        .nav-links{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          left: 0px;
          margin-right: 2.5rem;
        }
      }
    }
  }
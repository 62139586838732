.directory-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #91C03C;
  }

  @media only screen and (max-width:768px) {
    .directory-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: #91C03C;
    }
  }
.home{
    background-color: #92C03C;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}
.hero-container {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
    z-index: 1;
    
}

.hero-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
    }
}

.hero-slide.active {
    opacity: 1;
}

.hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #FFFDD0;
}

.hero-text h1 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 10px;
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.hero-text p {
    font-size: 16px;
    margin-bottom: 20px;
}

.hero-text button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #168986;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    
}

.hero-text button:hover {
    background-color: #44949F;
}
.product-head{
    text-align: center;
    align-items: center; 
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    font-size: 60px;
}

.testimonials-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping of testimonials */
 
    .testimonial-head{
        text-align: center;
        font-size: 50px;
        color: transparent;
        font-family: Arial, Helvetica, sans-serif;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
    }

}
.testimonials{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-wrap: wrap;

  

    .testimonial{
        margin: 20px; 
        padding: 20px; 
        border-radius: 10px; 
        background-color: #f9f9f9; 
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
        text-align: center;
        width: 300px; /* Adjust width as needed */
        display: inline-block;
        white-space: normal;

        
        
        .testimonial-image{
            width: 150px; 
            height: 150px; 
            border-radius: 50%; 
            margin-top: 10px;
        }

        .testimonial-content{
            margin-bottom: 15px;

            .content{
                font-size: 16px; 
                line-height: 1.5;
                overflow: hidden;
                white-space: wrap;
            }

            .author{
                font-weight: bold;
            }

            .location{
                font-weight: bold;
            }
        }
    }

}

.client-sections{
    display: flex; 
    flex-direction: column; 
    justify-content: space-between;

    .client-head{
        align-items: center; 
        text-align: center;
        color: transparent;
        font-family: Arial, Helvetica, sans-serif;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
        font-size: 50px;
    }

    .client-section{
        display: flex; 
        justify-content: space-around; 
        align-items: center; 
        flex-wrap: wrap;


        .client{
            margin: 20px; 
            width: 90px; 
            height: 90%; 
            overflow: hidden; 
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
            transform: scale(1);
            transition: transform 0.3s ease; /* Add transition for smooth scaling */
        
            .client-image{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .client:hover{
            transform: scale(1.5);
        }

    }
}

@media only screen and (max-width:768px) {
    .home{
        width: 100%;

        .hero-container {
            position: relative;
            width: 100%;
            height: 400px;
            overflow: hidden;
            z-index: 1;
            
            

            .hero-text h1 {
                font-size: 20px;
                font-weight: 400;
                margin-bottom: 10px;
                overflow: hidden;
                color: transparent;
                font-family: Arial, Helvetica, sans-serif;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: white;
            }
        }
        
    }

}
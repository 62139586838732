.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it's above other content */
  
    .popup-content {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      text-align: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      position: relative;
    }
  
    .close-btn {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 24px;
      background: none;
      border: none;
      cursor: pointer;
      color: #333;
    }
  
    h2 {
      font-size: 24px;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 16px;
      margin-bottom: 20px;
    }
  
    .donate-btn {
      padding: 10px 20px;
      font-size: 16px;
      background-color: #92C03C;
      color: black;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
  
      &:hover {
        background-color: #BED735;
      }
    }
  }
  
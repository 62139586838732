.footer {
    background-color: #168986;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .footer-logo img {
    width: 100px; /* Adjust size as needed */
    height: auto;
  }
  
  .footer-info{
    justify-content: space-evenly;

    p:first-of-type {
      margin-bottom: 7px;
    }
    
  }
  .footer-info p {
    margin: 5px 0;
  }
  
  .footer-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-links a {
    color: #333;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .footer-links a:hover {
    color: #007bff; /* Change color on hover */
  }

  .socials {
    display: flex;
    justify-content: center;
    width: 200px; // Adjust as needed
    align-items: center;
    margin-left: 175px;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  
  .icon-facebook,
  .icon-twitter,
  .icon-instagram,
  .icon-mail {
    margin: 13px;
    width: 30px; // Adjust icon size as needed
    height: 30px; // Adjust icon size as needed
    fill: #000; // Adjust icon color as needed
    transition: fill 0.3s ease; // Smooth transition on hover or other state changes
    
    &:hover {
      fill: #3b5998; // Adjust hover color as needed
    }
  }
  
  @media only screen and (max-width:768px) {
    .footer-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3%;

      .footer-info{
        width: 50%;
      }

      .socials {
        display: flex;
        justify-content: center;
        width: 200px; // Adjust as needed
        align-items: center;
        margin-left: 6px;
        // margin-top: 7px;
        // margin-bottom: 7px;
      }
      
      .icon-facebook,
      .icon-twitter,
      .icon-instagram,
      .icon-mail {
        margin: 13px;
        width: 30px; // Adjust icon size as needed
        height: 30px; // Adjust icon size as needed
        fill: #000; // Adjust icon color as needed
        transition: fill 0.3s ease; // Smooth transition on hover or other state changes
        
        &:hover {
          fill: #3b5998; // Adjust hover color as needed
        }
      }
    }
  }
  
.product-container {
    min-width: 30%;
    height: 240px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    margin: 0 7.5px 15px;
    overflow: hidden;
  
    &:hover {
      cursor: pointer;
  
      & .background-image {
        transform: scale(1.1);
        transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
      }
  
      & .category-body-container {
        opacity: 0.9;
      }
    }
  
    &.large {
      height: 380px;
    }
  
    &:first-child {
      margin-right: 7.5px;
    }
  
    &:last-child {
      margin-left: 7.5px;
    }
  
    .background-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
  
    .product-body-container {
      height: 30%;
      padding: 0 25px;
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid black;
      background-color: white;
      opacity: 0.7;
      position: absolute;
      color: black;
  
      h2 {
        font-weight: bold;
        margin: 0 6px 0;
        font-size: 22px;
        color: black;
      }
  
      p {
        font-weight: medium;
        font-size: 16px;
        color: black;
        
      }
    }
  }

  @media only screen and (max-width:768px) {
    .product-container{
      display: flex;
      flex-direction: column;

      .product-body-container {
        height: 28%;
        padding: 0 25px;
        width: 22%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid black;
        background-color: white;
        opacity: 0.7;
        position: absolute;
        color: black;
    
        h2 {
          font-weight: bold;
          margin: 0 6px 0;
          font-size: 12px;
          color: black;
        }
    
        p {
          font-weight: medium;
          font-size: 10px;
          color: black;
          
        }
      }
    }

  }
.trainings{
    background-color: #92C03C;
    font-family: Arial, Helvetica, sans-serif;

.trainings-container{
    display: flex;
    flex-direction: column;
    height: 80%;
    margin: 0 auto;

    h1{
        text-align: center;
        color: wheat;
    }

    .courses{
        display: block;

            .first-course{
            display: flex;
            width: 95%;
            height: 40%;
            background-color: rgba(0, 0, 0, 0.5);
            align-items: center;
            margin-left: 3%;
            border-radius: 30px;

            img{
                width: 550px;
                height: 550px;
                border-radius: 5px; 
                // margin-left: 3%;
            }


            .course{
                margin-left: 1%; 
                
                .course-title{
                    font-size: 30px;
                    text-align: left;
                    color: wheat;
                    margin-left: 5%;
                }


                ul{
                    margin-left: 3%;
                    margin-right: 5%;
                    width: 67%;
                    font-size: 30px;
                    text-align: left;
                    color: wheat;
                    list-style-type: none;


                li::before{
                        content: '';
                        display: inline-block;
                        width: 20px; /* Adjust size as needed */
                        height: 20px; /* Adjust size as needed */
                        background-image: url("../../../assets/icons8-approval-48.png");
                        background-size: contain;
                        margin-right: 8px; /* Adjust spacing between tick and text */
                      }

                      li{
                        text-align: left; /* or 'left' or 'right' depending on your preference */
                        white-space: wrap; /* Prevents wrapping to multiple lines */
                        justify-content: space-between;
                      }
                    }

                   
                }
            }

            .second-course{
                display: flex;
                width: 95%;
                height: 40%;
                background-color: rgba(0, 0, 0, 0.5);
                align-items: center;
                margin-left: 3%;
                border-radius: 30px;
                margin-top: 3%;
    
                img{
                    width: 540px;
                    height: 650px;
                    border-radius: 5px; 
                    // margin-left: 3%;
                }
    
    
                .course{
                    margin-left: 1%; 
                    
                    .course-title{
                        font-size: 30px;
                        text-align: left;
                        color: wheat;
                        margin-left: 5%;
                    }
    
    
                    ul{
                        margin-left: 3%;
                        margin-right: 2%;
                        width: 67%;
                        font-size: 30px;
                        text-align: left;
                        color: wheat;
                        list-style-type: none;
                        justify-content: space-between;
    
    
                    li::before{
                            content: '';
                            display: inline-block;
                            width: 28px; /* Adjust size as needed */
                            height: 28px; /* Adjust size as needed */
                            background-image: url("../../../assets/icons8-approval-48.png");
                            background-size: contain;
                            margin-right: 8px; /* Adjust spacing between tick and text */
                          }
    
                          li{
                            text-align: left; /* or 'left' or 'right' depending on your preference */
                            white-space: wrap; /* Prevents wrapping to multiple lines */
                          }
                        }
    
                       
                    }
            }

            .third-course{
                    display: flex;
                    width: 95%;
                    height: 40%;
                    background-color: rgba(0, 0, 0, 0.5);
                    align-items: center;
                    margin-left: 3%;
                    border-radius: 30px;
                    margin-top: 3%;
        
                    img{
                        width: 550px;
                        height: 700px;
                        border-radius: 5px; 
                        // margin-left: 3%;
                    }
        
        
                    .course{
                        margin-left: 1%; 
                        
                        .course-title{
                            font-size: 30px;
                            text-align: left;
                            color: wheat;
                            margin-left: 5%;
                        }
        
        
                        ul{
                            margin-left: 3%;
                            margin-right: 2%;
                            width: 67%;
                            font-size: 30px;
                            text-align: left;
                            color: wheat;
                            list-style-type: none;
                            justify-content: space-between;
        
        
                        li::before{
                                content: '';
                                display: inline-block;
                                width: 28px; /* Adjust size as needed */
                                height: 28px; /* Adjust size as needed */
                                background-image: url("../../../assets/icons8-approval-48.png");
                                background-size: contain;
                                margin-right: 8px; /* Adjust spacing between tick and text */
                              }
        
                              li{
                                text-align: left; /* or 'left' or 'right' depending on your preference */
                                white-space: wrap; /* Prevents wrapping to multiple lines */
                              }
                            }
        
                           
                        }
            }

            .fourth-course{
                display: flex;
                width: 95%;
                height: 40%;
                background-color: rgba(0, 0, 0, 0.5);
                align-items: center;
                margin-left: 3%;
                border-radius: 30px;
                margin-top: 3%;
                margin-bottom: 3%;
    
                img{
                    width: 550px;
                    height: 780px;
                    border-radius: 5px; 
                    // margin-left: 3%;
                }
    
    
                .course{
                    margin-left: 1%; 
                    
                    .course-title{
                        font-size: 30px;
                        text-align: left;
                        color: wheat;
                        margin-left: 5%;
                    }
    
    
                    ul{
                        margin-left: 3%;
                        margin-right: 2%;
                        width: 67%;
                        font-size: 30px;
                        text-align: left;
                        color: wheat;
                        list-style-type: none;
                        justify-content: space-between;
    
    
                    li::before{
                            content: '';
                            display: inline-block;
                            width: 28px; /* Adjust size as needed */
                            height: 28px; /* Adjust size as needed */
                            background-image: url("../../../assets/icons8-approval-48.png");
                            background-size: contain;
                            margin-right: 8px; /* Adjust spacing between tick and text */
                          }
    
                          li{
                            text-align: left; /* or 'left' or 'right' depending on your preference */
                            white-space: wrap; /* Prevents wrapping to multiple lines */
                          }
                        }
    
                       
                    }
            }

            .fifth-course{
                display: flex;
                width: 95%;
                height: 40%;
                background-color: rgba(0, 0, 0, 0.5);
                align-items: center;
                margin-left: 3%;
                margin-bottom: 3%;
                border-radius: 30px;
    
                img{
                    width: 650px;
                    height: 1080px;
                    border-radius: 5px; 
                    // margin-left: 3%;
                }
    
    
                .course{
                    margin-left: 1%; 
                    
                    .course-title{
                        font-size: 30px;
                        text-align: left;
                        color: wheat;
                        margin-left: 5%;
                    }
    
    
                    ul{
                        margin-left: 3%;
                        margin-right: 5%;
                        width: 67%;
                        font-size: 30px;
                        text-align: left;
                        color: wheat;
                        list-style-type: none;
    
    
                    li::before{
                            content: '';
                            display: inline-block;
                            width: 20px; /* Adjust size as needed */
                            height: 20px; /* Adjust size as needed */
                            background-image: url("../../../assets/icons8-approval-48.png");
                            background-size: contain;
                            margin-right: 8px; /* Adjust spacing between tick and text */
                          }
    
                          li{
                            text-align: left; /* or 'left' or 'right' depending on your preference */
                            white-space: wrap; /* Prevents wrapping to multiple lines */
                            justify-content: space-between;
                          }
                        }
    
                       
                    }
                }
        }


        @media only screen and (max-width:768px) {
            .courses{
                display: block;
        
                    .first-course{
                    display: flex;
                    flex-direction: column;
                    width: 95%;
                    height: 40%;
                    background-color: rgba(0, 0, 0, 0.5);
                    align-items: center;
                    margin-left: 3%;
                    border-radius: 30px;
        
                    img{
                        width: 200px;
                        height: 200px;
                        border-radius: 5px; 
                        margin-top: 3%;
                    }
        
        
                    .course{

                        
                        .course-title{
                            font-size: 20px;
                            text-align: left;
                            color: wheat;
                            margin-left: 5%;
                        }
        
        
                        ul{
                            margin-left: 3%;
                            margin-right: 5%;
                            width: 67%;
                            font-size: 16px;
                            text-align: left;
                            color: wheat;
                            list-style-type: none;
        
        
                        li::before{
                                content: '';
                                display: inline-block;
                                width: 10px; /* Adjust size as needed */
                                height: 10px; /* Adjust size as needed */
                                background-image: url("../../../assets/icons8-approval-48.png");
                                background-size: contain;
                                margin-right: 8px; /* Adjust spacing between tick and text */
                              }
        
                              li{
                                text-align: left; /* or 'left' or 'right' depending on your preference */
                                white-space: wrap; /* Prevents wrapping to multiple lines */
                                justify-content: space-between;
                              }
                            }
        
                           
                        }
                    }
        
                    .second-course{
                        display: flex;
                        flex-direction: column;
                        width: 95%;
                        height: 40%;
                        background-color: rgba(0, 0, 0, 0.5);
                        align-items: center;
                        margin-left: 3%;
                        border-radius: 30px;
                        margin-top: 3%;
            
                        img{
                            width: 240px;
                            height: 250px;
                            border-radius: 5px; 
                            margin-top: 3%;
                        }
            
            
                        .course{
                            margin-left: 1%; 
                            
                            .course-title{
                                font-size: 20px;
                                text-align: left;
                                color: wheat;
                                margin-left: 5%;
                            }
            
            
                            ul{
                                margin-left: 3%;
                                margin-right: 2%;
                                width: 67%;
                                font-size: 16px;
                                text-align: left;
                                color: wheat;
                                list-style-type: none;
                                justify-content: space-between;
            
            
                            li::before{
                                    content: '';
                                    display: inline-block;
                                    width: 10px; /* Adjust size as needed */
                                    height: 10px; /* Adjust size as needed */
                                    background-image: url("../../../assets/icons8-approval-48.png");
                                    background-size: contain;
                                    margin-right: 8px; /* Adjust spacing between tick and text */
                                  }
            
                                  li{
                                    text-align: left; /* or 'left' or 'right' depending on your preference */
                                    white-space: wrap; /* Prevents wrapping to multiple lines */
                                  }
                                }
            
                               
                            }
                    }
        
                    .third-course{
                            display: flex;
                            flex-direction: column;
                            width: 95%;
                            height: 40%;
                            background-color: rgba(0, 0, 0, 0.5);
                            align-items: center;
                            margin-left: 3%;
                            border-radius: 30px;
                            margin-top: 3%;
                
                            img{
                                width: 210px;
                                height: 200px;
                                border-radius: 5px; 
                                margin-top: 3%;
                            }
                
                
                            .course{
 
                                
                                .course-title{
                                    font-size: 20px;
                                    text-align: center;
                                    color: wheat;
                                  
                                }
                
                
                                ul{
                                    margin-left: 3%;
                                    margin-right: 2%;
                                    width: 67%;
                                    font-size: 16px;
                                    text-align: left;
                                    color: wheat;
                                    list-style-type: none;
                                    justify-content: space-between;
                
                
                                li::before{
                                        content: '';
                                        display: inline-block;
                                        width: 10px; /* Adjust size as needed */
                                        height: 10px; /* Adjust size as needed */
                                        background-image: url("../../../assets/icons8-approval-48.png");
                                        background-size: contain;
                                        margin-right: 8px; /* Adjust spacing between tick and text */
                                      }
                
                                      li{
                                        text-align: left; /* or 'left' or 'right' depending on your preference */
                                        white-space: wrap; /* Prevents wrapping to multiple lines */
                                      }
                                    }
                
                                   
                                }
                    }
        
                    .fourth-course{
                        display: flex;
                        flex-direction: column;
                        width: 95%;
                        height: 40%;
                        background-color: rgba(0, 0, 0, 0.5);
                        align-items: center;
                        margin-left: 3%;
                        border-radius: 30px;
                        margin-top: 3%;
                        margin-bottom: 3%;
            
                        img{
                            width: 250px;
                            height: 280px;
                            border-radius: 5px; 
                            margin-top: 3%;
                        }
            
            
                        .course{
 
                            
                            .course-title{
                                font-size: 20px;
                                text-align: center;
                                color: wheat;
                              
                            }
            
            
                            ul{
                                margin-left: 3%;
                                margin-right: 2%;
                                width: 67%;
                                font-size: 16px;
                                text-align: left;
                                color: wheat;
                                list-style-type: none;
                                justify-content: space-between;
            
            
                            li::before{
                                    content: '';
                                    display: inline-block;
                                    width: 10px; /* Adjust size as needed */
                                    height: 10px; /* Adjust size as needed */
                                    background-image: url("../../../assets/icons8-approval-48.png");
                                    background-size: contain;
                                    margin-right: 8px; /* Adjust spacing between tick and text */
                                  }
            
                                  li{
                                    text-align: left; /* or 'left' or 'right' depending on your preference */
                                    white-space: wrap; /* Prevents wrapping to multiple lines */
                                  }
                                }
            
                               
                            }
                    }

                    .fifth-course{
                        display: flex;
                        flex-direction: column;
                        width: 95%;
                        height: 40%;
                        background-color: rgba(0, 0, 0, 0.5);
                        align-items: center;
                        margin-left: 3%;
                        margin-bottom: 3%;
                        border-radius: 30px;
            
                        img{
                            width: 250px;
                            height: 280px;
                            border-radius: 5px; 
                            // margin-left: 3%;
                        }
            
            
                        .course{
                            margin-left: 1%; 
                            
                            .course-title{
                                font-size: 20px;
                                text-align: left;
                                color: wheat;
                                margin-left: 5%;
                            }
            
            
                            ul{
                                margin-left: 3%;
                                margin-right: 5%;
                                width: 67%;
                                font-size: 16px;
                                text-align: left;
                                color: wheat;
                                list-style-type: none;
            
            
                            li::before{
                                    content: '';
                                    display: inline-block;
                                    width: 20px; /* Adjust size as needed */
                                    height: 20px; /* Adjust size as needed */
                                    background-image: url("../../../assets/icons8-approval-48.png");
                                    background-size: contain;
                                    margin-right: 8px; /* Adjust spacing between tick and text */
                                  }
            
                                  li{
                                    text-align: left; /* or 'left' or 'right' depending on your preference */
                                    white-space: wrap; /* Prevents wrapping to multiple lines */
                                    justify-content: space-between;
                                  }
                                }
            
                               
                            }
                        }
                }
        }
    }
}


.about-page {
  background-color: #92C03C;
  font-family: Arial, Helvetica, sans-serif;
    
  
  .about-hero {
      background-image: url('../../../assets/about7.jpg'); /* Replace with actual image path */
      background-size: cover;
      background-position: center;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      
  
      h1 {
        color: #92C03C;
        font-size: 55px;
        font-weight: 800;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      }
    }
  
        .about-content {
            display: flex;
            flex-direction: column;
            height: 80%;
            margin: 0 auto;
            
      p {
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 20px;
      }

    }

    .about-card {
      width: 95%;
      height: 40%;
      background-color: rgba(0, 0, 0, 0.5);
      align-items: center;
      // justify-content: space-between;
      display: flex;
      // border-radius: 30px ;
      margin-left: 3%;
      margin-top: 3%;
      // margin-bottom: 3%;
      
      .about-image{
        width:800px;
        height: 510px;
        align-items: center;
        // border-radius: 30px;
      }

      .about-text {
        margin-left: 3%;
        margin-right: 2%;
        width: 67%;
        font-size: 30px;
        text-align: left;
        color: wheat;
      }

    }

    .about-card1 {
      width: 95%;
      height: 40%;
      background-color:rgba(0, 0, 0, 0.5);
      align-items: center;
      justify-content: space-between;
      display: flex;
      flex-direction: row-reverse;
      margin-left: 3%;
      
      .about-image-reverse {
        width: 830px;
        height: 510px;
        align-items: center;
      }

      .about-text-reverse {
        margin-left: 3%;
        margin-right: 2%;
        width: 67%;
        font-size: 30px;
        text-align: left;
        color: wheat;
        justify-content: center;
      }

    }

    .about-card2 {
      width: 95%;
      height: 40%;
      background-color: rgba(0, 0, 0, 0.5);
      align-items: center;
      display: flex;
      margin-left: 3%;

      .about-image{
        width:670px;
        height: 670px;
        align-items: center;
        // border-radius: 30px;
      }

      .about-text {
        margin-left: 3%;
        margin-right: 2%;
        width: 67%;
        font-size: 30px;
        text-align: left;
        color: wheat;
      }
    }

    .about-card3{
      width: 95%;
      height: 40%;
      background-color: rgba(0, 0, 0, 0.5);
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      margin-left: 3%;

      .about-image-reverse {
        width: 590px;
        height: 550px;
        align-items: center;
      }

      .about-text-reverse {
        margin-left: 3%;
        margin-right: 2%;
        width: 67%;
        font-size: 30px;
        text-align: left;
        color: wheat;
        justify-content: center;
      }
    }

    .about-card4 {
      width: 95%;
      height: 40%;
      background-color: rgba(0, 0, 0, 0.5);
      align-items: center;
      display: flex;
      margin-left: 3%;


      .about-image{
        width:1050px;
        height: 600px;
        align-items: center;
      }

      .about-text {
        margin-left: 3%;
        margin-right: 2%;
        width: 67%;
        font-size: 30px;
        
        color: wheat;

        h1{
          font-size: 50px;
          text-align: center;
        }

        .about-text-body {
          font-size: 30px;
          text-align: left;
        }
      }
    }


    .about-card5 {
      width: 95%;
      height: 40%;
      background-color:rgba(0, 0, 0, 0.5);
      align-items: center;
      justify-content: space-between;
      display: flex;
      flex-direction: row-reverse;
      margin-left: 3%;
      margin-bottom: 3%;

      .about-image-reverse {
        width: 600px;
        height: 465px;
        align-items: center;
      }

      .text{
        margin-left: 3%;
        margin-right: 2%;
        width: 67%;
        font-size: 30px;
        
        color: wheat;
        justify-content: center;

        .text-head{
          font-size: 45px;
          text-align: center;
        }

        .text-body {
          font-size: 30px;
          text-align: left;
        }
      }
    }


    @media only screen and (max-width:768px) {
      .about-content {
        display: flex;
        flex-direction: column;
        height: 80%;
        margin: 0 auto;


        .about-card {
          width: 300px;
          height: 40%;
          background-color: rgba(0, 0, 0, 0.5);
          align-items: center;
          // justify-content: space-between;
          display: flex;
          flex-direction: column;
          border-radius: 30px ;
           margin-left: 12%;
          // margin-top: 3%;
          // margin-bottom: 3%;
          
          .about-image{
            margin-top: 6px;
            width:150px;
            height: 150px;
            align-items: center;
            border-radius: 10px;
          }
    
          .about-text {
            margin-left: 3%;
            margin-right: 2%;
            width: 67%;
            font-size: 15px;
            text-align: center;
            color: wheat;
          }
    
        }
    
        .about-card1 {
          width: 300px;
          height: 40%;
          background-color:rgba(0, 0, 0, 0.5);
          align-items: center;
          justify-content: space-between;
          display: flex;
          flex-direction: column;
          border-radius: 30px ;
          margin-left: 12%;
          margin-top: 3%;
          
          .about-image-reverse {
            margin-top: 6px;
            width: 150px;
            height: 150px;
            align-items: center;
            border-radius: 10px;
          }
    
          .about-text-reverse {
            margin-left: 3%;
            margin-right: 2%;
            width: 67%;
            font-size: 15px;
            text-align:  center;
            color: wheat;
            justify-content: center;
          }
    
        }
    
        .about-card2 {
          width: 300px;
          height: 40%;
          background-color: rgba(0, 0, 0, 0.5);
          align-items: center;
          display: flex;
          flex-direction: column;
          border-radius: 30px ;
           margin-left: 12%;
           margin-top: 3%;
          
          .about-image{
            margin-top: 6px;
            width:150px;
            height: 150px;
            align-items: center;
            border-radius: 10px;
          }
    
          .about-text {
            margin-left: 3%;
            margin-right: 2%;
            width: 67%;
            font-size: 15px;
            text-align: center;
            color: wheat;
          }
        }

        .about-card3{
          width: 300px;
          height: 40%;
          background-color:rgba(0, 0, 0, 0.5);
          align-items: center;
          justify-content: space-between;
          display: flex;
          flex-direction: column;
          border-radius: 30px ;
          margin-left: 12%;
          margin-top: 3%;
          
          .about-image-reverse {
            margin-top: 6px;
            width: 150px;
            height: 150px;
            align-items: center;
            border-radius: 10px;
          }
    
          .about-text-reverse {
            margin-left: 3%;
            margin-right: 2%;
            width: 67%;
            font-size: 15px;
            text-align:  center;
            color: wheat;
            justify-content: center;
          }
        }

        .about-card4 {
          width: 300px;
          height: 40%;
          background-color: rgba(0, 0, 0, 0.5);
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          border-radius: 30px ;
          margin-left: 12%;
          margin-top: 3%;
    
          .about-image{
            margin-top: 6px;
            width: 150px;
            height: 150px;
            align-items: center;
            border-radius: 10px;
          }
    
          .about-text {
            margin-left: 3%;
            margin-right: 2%;
            width: 67%;
            
            color: wheat;
    
            h1{
              font-size: 20px;
              text-align: center;
            }
    
            .about-text-body {
              font-size: 15px;
              text-align: center;
            }
          }
        }
    
    
        .about-card5 {
          width: 300px;
          height: 40%;
          background-color:rgba(0, 0, 0, 0.5);
          align-items: center;
          justify-content: space-between;
          display: flex;
          flex-direction: column;
          border-radius: 30px ;
          margin-left: 12%;
          margin-top: 3%;
          margin-bottom: 3%;
    
          .about-image-reverse {
            margin-top: 6px;
            width: 150px;
            height: 150px;
            align-items: center;
            border-radius: 10px;
          }
    
          .text{
            margin-left: 3%;
            margin-right: 2%;
            width: 67%;
            font-size: 30px;
            
            color: wheat;
            justify-content: center;
    
            .text-head{
              font-size: 20px;
              text-align: center;
            }
    
            .text-body {
              font-size: 15px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  


  
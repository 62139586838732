.mgps{
    background-color: #92C03C;
    font-family: Arial, Helvetica, sans-serif;
    



.mgps-hero{
    background-image: url(../../../assets/mgps8.png);
    background-size: contain;
    background-position: center;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        color: black;
        font-size: 36px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      }
}

    .mgps-container {
        display: flex;
        flex-direction: column;
        height: 80%;
        margin: 0 auto;
    }


.mgps-card{
  width: 95%;
  height: 40%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  display: flex;
  border-radius: 30px;
  margin-left: 3%;
  margin-top: 3%;

  .mgps-image{
    width: 600px;
    height: 510px;
    align-items: left;
    border-radius: 30px;
  }

  .mgps-text{
    margin-left: 3%;
      margin-right: 2%;
      width: 67%;
      font-size: 30px;
      text-align: left;
      color: wheat;
  }
}

.mgps-card-reverse{
  width: 95%;
  height: 40%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  border-radius: 30px;
  margin-left: 3%;
  margin-top: 3%;

  .mgps-image-reverse{
    width: 800px;
    height: 510px;
    align-items: left;
    border-radius: 30px;
  }

  .mgps-text-reverse{
    margin-left: 3%;
      margin-right: 2%;
      width: 67%;
      font-size: 30px;
      text-align: left;
      color: wheat;
  }
}

.mgps-card1{
  width: 95%;
  height: 40%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  display: flex;
  border-radius: 30px;
  margin-left: 3%;
  margin-top: 3%;

  .mgps-image1{
    width: 600px;
    height: 550px;
    align-items: left;
    border-radius: 30px;
  }

  .mgps-text1{
    margin-left: 3%;
      margin-right: 2%;
      width: 67%;
      font-size: 30px;
      text-align: left;
      color: wheat;
  }
}

.mgps-card-reverse1 {
  width: 95%;
  height: 40%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  border-radius: 30px;
  margin-left: 3%;
  margin-top: 3%;

  .mgps-image-reverse1 {
    width: 700px;
    height: 510px;
    align-items: left;
    border-radius: 30px;
  }

  .mgps-text-reverse1 {
    margin-left: 3%;
      margin-right: 2%;
      width: 67%;
      font-size: 30px;
      text-align: left;
      color: wheat;
  }
}

.mgps-card2 {
    width: 95%;
    height: 40%;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    // justify-content: space-between;
    display: flex;
    border-radius: 30px ;
    margin-left: 3%;
    margin-top: 3%;
    margin-bottom: 4%;
    
    .mgps-image2{
      width:800px;
      height: 610px;
      align-items: center;
      border-radius: 30px;
    }

    .mgps-text2 {
      margin-left: 3%;
      margin-right: 2%;
      width: 67%;
      font-size: 30px;
      text-align: left;
      color: wheat;
      list-style-type: none;
    
      .mgps-subtext::before{
        content: '';
        display: inline-block;
        width: 20px; /* Adjust size as needed */
        height: 20px; /* Adjust size as needed */
        background-image: url("../../../assets/tick.svg");
        background-size: contain;
        margin-right: 8px; /* Adjust spacing between tick and text */
      }
  }

    

  }


  @media only screen and (max-width:768px) {
    .mgps-hero {
      text-align: center;
    }
    .mgps-card{
      width: 95%;
      height: 40%;
      background-color: rgba(0, 0, 0, 0.5);
      align-items: center;
      display: flex;
      flex-direction: column;
      border-radius: 30px;
      margin-left: 3%;
      margin-top: 3%;
    
      .mgps-image{
        margin-top: 3%;
        width: 200px;
        height: 210px;
        align-items: center;
        border-radius: 20px;
      }
    
      .mgps-text{
        margin-left: 3%;
          margin-right: 2%;
          margin-top: 20px;
          width: 67%;
          font-size: 16px;
          text-align: center;
          color: wheat;
      }
    }
    
    .mgps-card-reverse{
      width: 95%;
      height: 40%;
      background-color: rgba(0, 0, 0, 0.5);
      align-items: center;
      display: flex;
      flex-direction: column;
      border-radius: 30px;
      margin-left: 3%;
      margin-top: 3%;
    
      .mgps-image-reverse{
        width: 200px;
        height: 210px;
        align-items: center;
        border-radius: 30px;
      }
    
      .mgps-text-reverse{
        margin-left: 3%;
          margin-right: 2%;
          width: 67%;
          font-size: 16px;
          text-align: center;
          color: wheat;
      }
    }
    
    .mgps-card1{
      width: 95%;
      height: 40%;
      background-color: rgba(0, 0, 0, 0.5);
      align-items: center;
      display: flex;
      flex-direction: column;
      border-radius: 30px;
      margin-left: 3%;
      margin-top: 3%;
    
      .mgps-image1{
        margin-top: 3%;
        width: 200px;
        height: 220px;
        align-items: left;
        border-radius: 30px;
      }
    
      .mgps-text1{
        margin-left: 3%;
          margin-right: 2%;
          width: 67%;
          font-size: 16px;
          text-align: center;
          color: wheat;
      }
    }
    
    .mgps-card-reverse1 {
      width: 95%;
      height: 40%;
      background-color: rgba(0, 0, 0, 0.5);
      align-items: center;
      display: flex;
      flex-direction: column;
      border-radius: 30px;
      margin-left: 3%;
      margin-top: 3%;
    
      .mgps-image-reverse1 {
        margin-top: 3%;
        width: 200px;
        height: 170px;
        align-items: left;
        border-radius: 30px;
      }
    
      .mgps-text-reverse1 {
        margin-left: 3%;
          margin-right: 2%;
          width: 67%;
          font-size: 16px;
          text-align: center;
          color: wheat;
      }
    }
    
    .mgps-card2 {
        width: 95%;
        height: 40%;
        background-color: rgba(0, 0, 0, 0.5);
        align-items: center;
        // justify-content: space-between;
        display: flex;
        flex-direction: column;
        border-radius: 30px ;
        margin-left: 3%;
        margin-top: 3%;
        margin-bottom: 4%;
        
        .mgps-image2{
          margin-top: 3%;
          width:220px;
          height: 210px;
          align-items: center;
          border-radius: 30px;
        }
    
        .mgps-text2 {
          margin-left: 3%;
          margin-right: 2%;
          width: 67%;
          font-size: 16px;
          text-align: center;
          color: wheat;
          list-style-type: none;
        
          .mgps-subtext::before{
            content: '';
            display: inline-block;
            width: 20px; /* Adjust size as needed */
            height: 20px; /* Adjust size as needed */
            background-image: url("../../../assets/tick.svg");
            background-size: contain;
            margin-right: 8px; /* Adjust spacing between tick and text */
            
          }

          .mgps-subtext{
            text-align: left;
            margin-right: 2px;
          }
      }
    
        
    
      }
  }
}